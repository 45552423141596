.hurone-conditional-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // width: 100%;

  &_checkbox {
    width: 40%;
  }

  &_select {
    width: 60%;
  }
}
@primary-color: #00B0C7;