.hurone-phone-input {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  &_country {
    margin-right: 8px;
    
    &_option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      &_flag {
        font-size: 12px;
      }
    }
  }
}
@primary-color: #00B0C7;