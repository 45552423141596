.card-shadow {
    box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%),
      0 5px 12px 4px rgb(0 0 0 / 9%);
  }

.card-title {
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  color: #323232;
}

.appointments-card {
  .ant-page-header {
    background-color: #fff;
  }
  .ant-table-thead > tr > th {
    background-color: #fff;
  }
}

.treatment-plan-card {
  .ant-card-actions {
    background-color: #fff;
  }
}


@primary-color: #00B0C7;