.hurone-tag {
  height: 28px;
  margin: 3px;
  padding: 3px 8px;
  border: none;
  border-radius: 8px;
  background-color: #e5f7f9;
  color: #007787;
}

.hurone-tag-tooltip {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ant-select-multiple .ant-select-selection-item, .ant-select-multiple.ant-select-lg .ant-select-selection-item  {
  width: 40px;
  height: 28px;
  margin: 3px;
  padding: 0;
  border: none;
  border-radius: 8px;
  align-items: center;
  background-color: #e5f7f9;
  color: #007787;
}

@primary-color: #00B0C7;