.survey-table {
  .ant-table-thead > tr > th {
    background-color: #fff;
  }

  tr.ant-table-expanded-row > td, tr.ant-table-expanded-row:hover > td {
    background-color: #fff;
  }
}

.survey-answer {
  height: 20px; 
  display: flex; 
  align-items: center;

  p {
    margin: 0 5px;
  }
}
@primary-color: #00B0C7;