@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.less";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");

// There are some major variables below,
// all less variables could be found in
// https://github.com/ant-design/ant-design/tree/4.x-stable/components/style/themes/default.less

// font
@font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// base variables
@border-radius-base: 6px;

// colors
@primary-color: #00b0c7;
@error-color: #fa541c;
@text-color: #626262;
@body-background: #fbfbfb;
@component-background: #fbfbfb;

// sider
@layout-sider-background: #007787;
@menu-bg: transparent;
@menu-item-color: #626262;
@menu-highlight-color: #00b0c7;
@menu-inline-submenu-bg: transparent;
@menu-item-active-bg: transparent;

@menu-item-active-border-width: 0px;
@layout-trigger-background: rgba(0, 0, 0, 0.5);

.ant-layout-sider-children {
  background-color: #f8f9fd;
  // color: #007787;
  box-shadow: 0 4px 6px -6px #222;
  border-right-color: #ebeced;
  border-right-width: 1px;
  border-right-style: solid;
}

//card
@card-background: #fbfbfb;

// tabs
@tabs-card-head-background: #fbfbfb;

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0px;
}



.ant-form-item-label > label {
  font-weight: 500;
}

.ant-page-header {
  border-radius: @border-radius-base;
}

.ant-layout-header {
  border-radius: @border-radius-base;
  -webkit-box-shadow: 0 4px 6px -6px #222;
  -moz-box-shadow: 0 4px 6px -6px #222;
  box-shadow: 0 4px 6px -6px #222;
  z-index: 10000;
}
.ant-menu-submenu-popup > .ant-menu {
  background-color: #fff;
}
.ant-menu-tree-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  color: #fff;
  transform: translateY(-50%);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-card-actions {
  float: center;
}
.ant-menu-tree-arrow::before,
.ant-menu-tree-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-color: currentcolor;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: "";
}
.ant-menu-tree-arrow::before {
  transform: rotate(45deg) translateY(-2.5px);
}
.ant-menu-tree-arrow::after {
  transform: rotate(-45deg) translateY(2.5px);
}

* ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

* ::-webkit-scrollbar-track {
  background: #faf7ef;
}

* ::-webkit-scrollbar-thumb {
  background: #007787;
}

.spiner {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

// pagination

.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link {
  border: none;
  background-color: #fbfbfb;
}

.ant-pagination-item {
  // border-radius: 50%;
  // border: none;
  box-sizing: border-box;
  background-color: #fbfbfb;
}


.ant-pagination-item-active {
  background-color: #e5f7f9;

}

  .ant-typography {
    ul {
      li {
        padding: 0 0 0 0px;
      }
    }
  }

// tag
@tag-default-bg: #e5f7f9;
@tag-default-color: #007787;

//form
@label-color: @text-color;

// input
@input-height-lg: 48px;
@input-bg: #fff;

// datepicker
@picker-bg: #fff;

// select
@select-single-item-height-lg: 48px;
@select-multiple-item-height-lg: 48px;
@select-background: #fff;

// button
@btn-default-bg: #fff;
@btn-height-lg: 48px;
// @btn-default-color: #00B0C7;
// @btn-default-border: #00B0C7;

.ant-btn {
  font-weight: 650;
}

.ant-btn > .anticon + span {
  margin-left: 5px;
}

// table
@table-bg: #fff;
@table-body-sort-bg: #fff;
@table-row-hover-bg: #fff;
@table-header-color: #9f9da3;
@table-border-radius-base: 8px;

.ant-table-fixed {
  table-layout: fixed;
}

.ant-table-tbody > tr > td {
  word-wrap: break-word;
  // word-break: break-all;
  font-size: 13px;
}


// .ant-table-thead
//   > tr
//   > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
//   width: 0;
// }

.ant-table-thead th.ant-table-column-sort {
  background: #fbfbfb;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

.survey-circle {
  width: 10px;
  height: 10px;
  text-align: center;
  border-radius: 50%;
}

.survey-circle-pulsing {
  width: 10px;
  height: 10px;
  text-align: center;
  border-radius: 50%;
  animation: pulse 1.5s infinite;
}



@primary-color: #00B0C7;